import axios from "axios";
import { jwtDecode } from "jwt-decode";
export default {
    install(Vue, options) {
        Vue.prototype.$apex = apexService(options);
    }
}

let access_token = "";

function getApiKeyHeaders() {
    return {
        "x-organizer-key": localStorage.getItem("organizer-key"),
        "x-organizer-name": localStorage.getItem("organizer-username"),
    }
}

function getUserHeaders() {
    return {
        "Authorization": localStorage.getItem("session-token"),
    }
}

function apexService(config) {

    function getApiKey() {
        return localStorage.getItem("organizer-key");
    }

    function sanatizedEventId(eventId) {
        if (eventId)
            return eventId.replace(/[\W_]+/g, "_").substring(0, 30);
    }

    function getMatchPageUrl(router, organizer, matchId, eventId, page = "tournament.standings.scoreboard", game = "overall") {
        eventId = sanatizedEventId(eventId);
        return window.location.origin + router.resolve({ name: page, params: { organizer: organizer, matchSlug: matchId + (eventId ? '.' + eventId : ""), game } }).href
    }

    function getEventPageUrl(router, organizer, eventId, name, page = "event.overview") {
        name = sanatizedEventId(name);
        return window.location.origin + router.resolve({ name: page, params: { organizer: organizer, eventSlug: eventId + (name ? '.' + name : "") } }).href
    }

    function getPlayerPageUrl(router, playerId, playerName) {
        playerName = sanatizedEventId(playerName);
        return window.location.origin + router.resolve({ name: "player", params: { id: playerId + "." + playerName } }).href
    }

    function getMapDrawUrl(router, remoteId) {
        return window.location.origin + router.resolve({ name: "map", params: { remoteId } }).href
    }

    async function login(username, key) {
        let result = await axios.post(`${config.baseUrl}auth/organizer`, { username, key });

        return result.data;
    }

    async function getStatsFromCode(statsCode) {
        try {
            let stats = await axios.get(`${config.baseUrl}stats/code/${statsCode}`, { headers: getApiKeyHeaders() });
            return stats.data;
        } catch (err) {
            return err.response.data;
        }
    }

    async function getStats(matchId, game) {
        let stats = await axios.get(config.baseUrl + "stats/" + matchId + "/" + game, {headers: {'x-endpoint-access-token' : access_token}});
        return stats.data;
    }

    async function exportCsv(game, type, cols, stats) {
        let data = { header: [], rows: [] };
        data.header = cols.map(col => col.label);

        // get stats for either a specific game or overall teams
        const statsData = game !== "overall" ? stats.games.find(g => g.game === game).teams : stats.teams;

        if (statsData.length) {
            if (type === "team") {
                data.rows = statsData.map(team => {
                    let overallStats = {};
                    cols.forEach(col => {
                        if (Object.prototype.hasOwnProperty.call(team.overall_stats, col.key))
                            overallStats[col.key] = team.overall_stats[col.key];
                        else
                            overallStats[col.key] = "-";
                    })
                    return overallStats;
                })
            } else if (type === "player") {
                statsData.forEach(team => {
                    team.player_stats.forEach(player => {
                        let playerStats = {};
                        cols.forEach(col => {
                            if (col.key === "characterName" && game === "overall") {
                                playerStats.characterName = player.characters.join(", ");
                            } else if (col.key === "team") {
                                playerStats[col.key] = team.name;
                            } else if (Object.prototype.hasOwnProperty.call(player, col.key) && player[col.key] != null) {
                                playerStats[col.key] = player[col.key];
                            } else
                                playerStats[col.key] = "-";
                        })
                        data.rows.push(playerStats);
                    })
                })
            }
        }

        // print header as a string
        let dataString = JSON.stringify(data.header).replace(/\[|\]/g, "");
        data.rows.forEach(row => {
            dataString += "\n";
            Object.keys(row).forEach((key, i) => {
                if (i === 0)
                    dataString += `"${row[key]}"`;
                else
                    dataString += `,"${row[key]}"`;
            })
        })
        const dataBlob = new Blob([dataString]);

        const url = URL.createObjectURL(dataBlob);

        const link = document.createElement('a');
        link.setAttribute('download', stats.matchId + "_" + game + ".csv");
        link.setAttribute('href', url);
        link.click();
    }

    async function getGameList(matchId) {
        let stats = await axios.get(config.baseUrl + "games/" + matchId);
        return stats.data;
    }

    async function generateStats(matchId, statsCode, game, startTime, selectedUnclaimed, liveData) {
        let form = new FormData();

        form.append("matchId", matchId);
        form.append("statsCode", statsCode);
        form.append("game", game);
        if (startTime)
            form.append("startTime", startTime);
        if (selectedUnclaimed)
            form.append("selectedUnclaimed", selectedUnclaimed);
        if (liveData)
            form.append("liveData", liveData);

        try {
            let result = await axios.post(config.baseUrl + "stats", form, { headers: getApiKeyHeaders() })
            return result.data;
        } catch (err) {
            return err.response.data;
        }

    }

    async function deleteStats(matchId, game) {
        await axios.delete(config.baseUrl + "stats/" + matchId + "/" + game, { headers: getApiKeyHeaders() });
    }

    async function getBroadcastSettings(organizer) {
        let data = await axios.get(config.baseUrl + "settings/broadcast/" + organizer);
        return data.data;
    }

    async function setBroadcastSettings(organizer, display) {
        await axios.post(config.baseUrl + "settings/broadcast/" + organizer, display, { headers: getApiKeyHeaders() });
    }

    async function getPublicSettings(matchId) {
        let data = await axios.get(`${config.baseUrl}settings/match/${matchId}`);
        return data.data;
    }

    async function setPublicSettings(matchId, display) {
        await axios.post(`${config.baseUrl}settings/match/${matchId}`, display, { headers: getApiKeyHeaders() });
    }

    async function setSelectedMatch(organizer, match) {
        await axios.post(config.baseUrl + "organizer/match/" + organizer, { match }, { headers: getApiKeyHeaders() });
    }

    async function getSelectedMatch(organizer) {
        let { data } = await axios.get(config.baseUrl + "organizer/match/" + organizer, { headers: getApiKeyHeaders() });
        return data;
    }

    async function setMatchPolling(matchId, pollStart, pollEnd, statsCodes) {
        let { data } = await axios.post(config.baseUrl + "settings/auto_poll", { matchId, pollStart, pollEnd, statsCodes }, { headers: getApiKeyHeaders() })
        return data;
    }

    async function getMatchPolling(matchId) {
        let { data } = await axios.get(config.baseUrl + "settings/auto_poll/" + matchId, { headers: getApiKeyHeaders() })
        return data;
    }

    async function getMatchTeams(matchId) {
        let { data } = await axios.get(`${config.baseUrl}settings/match/${matchId}/teams`);
        return data;
    }

    async function setMatchTeam(matchId, teamId, name, regId) {
        await axios.post(`${config.baseUrl}settings/match/${matchId}/team`, {
            teamId,
            name,
            regId,
        }, { headers: getApiKeyHeaders() });
    }

    async function clearMatchTeam(matchId, teamId) {
        await axios.delete(`${config.baseUrl}settings/match/${matchId}/team/${teamId}`, { headers: getApiKeyHeaders() });
    }

    async function clearAllMatchTeams(matchId) {
        await axios.delete(`${config.baseUrl}settings/match/${matchId}/teams`, { headers: getApiKeyHeaders() });
    }

    async function archiveMatch(matchId) {
        await axios.post(config.baseUrl + "match/archive", { matchId }, { headers: getApiKeyHeaders() });
    }

    async function unArchiveMatch(matchId) {
        await axios.post(config.baseUrl + "match/archive", { matchId, archive: false }, { headers: getApiKeyHeaders() });
    }

    async function cloneMatch(eventId, matchId) {
        await axios.post(config.baseUrl + "match/clone", { matchId, eventId }, { headers: getApiKeyHeaders() });
    }

    async function cloneDataAndReset(eventId, matchId) {
        await axios.post(config.baseUrl + "match/clone_reset", { matchId, eventId }, { headers: getApiKeyHeaders() });
    }

    async function updateEventId(matchId, eventId) {
        await axios.patch(config.baseUrl + "match", { matchId, eventId }, { headers: getApiKeyHeaders() });
    }

    async function setOrganizerDefaultApexClient(organizer, client) {
        await axios.post(config.baseUrl + "settings/default_apex_client/" + organizer, { client }, { headers: getApiKeyHeaders() });
    }

    async function getOrganizerDefaultApexClient(organizer) {
        let { data } = await axios.get(config.baseUrl + "settings/default_apex_client/" + organizer, { headers: getApiKeyHeaders() });
        return data.selected_apex_client;
    }

    async function editScore(gameId, teamId, score) {
        let { data } = await axios.patch(`${config.baseUrl}stats/score/`, { gameId, teamId, score }, { headers: getApiKeyHeaders() });
        return data;
    }

    async function getMatchList(organizer, archived) {
        let result = await axios.get(config.baseUrl + "settings/match_list/" + organizer + (archived ? "?archived=true" : ""), { headers: getApiKeyHeaders() });
        return result.data;
    }

    async function getMatchById(matchId) {
        let result = await axios.get(config.baseUrl + "match/" + matchId);
        return result.data;
    }

    async function getLatest() {
        let data = await axios.get(config.baseUrl + "stats/latest");
        return data.data;
    }

    async function getShortLink(url) {
        let data = await axios.get(config.baseUrl + "short_link?url=" + encodeURIComponent(url));
        return data.data;
    }

    async function getLiveData(gameId, format = 'livedata') {
        let data = await axios.get(`${config.baseUrl}stats/${gameId}/livedata?format=${format}`);
        return data.data;
    }

    async function getLiveDataById(id, format = "stats") {
        let data = await axios.get(`${config.baseUrl}stats/livedata/${id}?format=${format}`);
        return data.data;
    }

    async function getLiveDataList(recent, unused) {
        let data = await axios.get(`${config.baseUrl}stats/livedata?recent=${recent}&unused=${unused}`, { headers: getApiKeyHeaders() });
        return data.data;
    }

    async function getPlayers(start, count, search) {
        let data = await axios.get(`${config.baseUrl}players?start=${start}&count=${count}&search=${search}`);
        return data.data;
    }

    async function getPlayer(id) {
        let data = await axios.get(`${config.baseUrl}player/${id}`);
        return data.data;
    }

    async function getPlayerMatches(id, start, count) {
        let data = await axios.get(`${config.baseUrl}player/${id}/matches?start=${start}&count=${count}`);
        return data.data;
    }

    async function createMatch(eventId) {
        let { data } = await axios.post(`${config.baseUrl}match`, { eventId }, { headers: getApiKeyHeaders() });
        return data;
    }

    async function getMatch(organizerName, eventId) {
        let { data } = await axios.get(`${config.baseUrl}match/${organizerName}/${encodeURIComponent(eventId)}`);
        return data;
    }

    async function setDrop(matchId, teamName, map, pass, token, color, drop) {
        let { data } = await axios.post(`${config.baseUrl}drop`, { matchId, teamName, token, map, pass, color, drop });
        return data;
    }

    async function deleteDrop(matchId, map, token, drop) {
        let { data } = await axios.delete(`${config.baseUrl}drop/${matchId}/${map}/${token}${drop ? '/' + drop : ''}`);
        return data;
    }

    async function deleteDropAdmin(matchId, map, teamName) {
        console.log("teamName", teamName, encodeURIComponent(teamName))
        let { data } = await axios.delete(`${config.baseUrl}drop_delete_admin/${matchId}/${map}${teamName ? '?team=' + encodeURIComponent(teamName) : ''}`, { headers: getApiKeyHeaders() });
        return data;
    }

    async function getDrops(matchId, map, token) {
        let { data } = await axios.get(`${config.baseUrl}drops/${matchId}/${map}${token ? '/' + token : ''}`);
        return data;
    }

    async function getDropsHistory(matchId, map) {
        let { data } = await axios.get(`${config.baseUrl}drops_history/${matchId}/${map}`);
        return data;
    }

    async function getDraftStatus(matchId) {
        let { data } = await axios.get(`${config.baseUrl}draft/${matchId}`);
        return data;
    }

    async function makeDraftClaim(matchId, pass, order, teamName, color, map, drop, round) {
        let { data } = await axios.post(`${config.baseUrl}draft/`, {matchId, pass, order, teamName, color, map, drop,round});
        return data;
    }

    async function resetDraft(matchId) {
        let { data } = await axios.delete(`${config.baseUrl}draft/${matchId}`, { headers: getApiKeyHeaders() });
        return data;
    }

    async function sendClientCommand(client, command) {
        let data = await axios.post(`${config.baseUrl}live/cmd/${client}`, command, { headers: getApiKeyHeaders() });
        return data;
    }

    let connections = {};
    function getLiveDataWs(organizer, client, admin) {
        const name = organizer + ":" + client;
        if (!connections[name]) {
            connections[name] = new WebSocket(`${config.wsReadUrl}/${organizer}/${client}` + (admin ? `/${this.getApiKey()}` : ""));
            connections[name].onclose = () => {
                connections[name] = undefined
            };
        }
        return connections[name];
    }

    async function readLiveData(organizer, client) {
        let { data } = await axios.get(`${config.baseUrl}live/read/${organizer}/${client}`);
        return data;
    }

    async function getClients(organizer) {
        let data = await axios.get(`${config.baseUrl}live/clients/${organizer}`);
        return data.data;
    }

    async function addClient(client) {
        let data = await axios.post(`${config.baseUrl}live/clients/`, { client }, { headers: getApiKeyHeaders() });
        return data.data;
    }

    async function pageView(to, from) {
        let {data} = await axios.post(`${config.baseUrl}view`, { to, from });
        access_token = data.access_token;
    }

    // events
    async function getEventList(orgId) {
        let { data } = await axios.get(`${config.baseUrl}events/${orgId}`);
        return data;
    }

    async function getEventListAdmin(orgId) {
        let { data } = await axios.get(`${config.baseUrl}events/${orgId}/admin`, { headers: getApiKeyHeaders() });
        return data;
    }


    async function getEvent(eventId) {
        let { data } = await axios.get(`${config.baseUrl}event/${eventId}`);
        return data;
    }

    async function getEventAdmin(eventId) {
        let { data } = await axios.get(`${config.baseUrl}event/${eventId}/admin`, { headers: getApiKeyHeaders() });
        return data;
    }

    async function createEvent(event) {
        let { data } = await axios.post(`${config.baseUrl}event/`, event, { headers: getApiKeyHeaders() });
        return data;
    }

    async function updateEvent(event) {
        let { data } = await axios.patch(`${config.baseUrl}event/`, event, { headers: getApiKeyHeaders() });
        return data;
    }

    async function addMatchToEvent(eventId, matchId, groupId) {
        let { data } = await axios.post(`${config.baseUrl}event/match`, { eventId, matchId, groupId }, { headers: getApiKeyHeaders() });
        return data;
    }

    async function removeMatchFromEvent(eventId, matchId, groupId) {
        let { data } = await axios.delete(`${config.baseUrl}event/match/${eventId}/${matchId}/${groupId}`, { headers: getApiKeyHeaders() });
        return data;
    }

    async function createEventGroup(eventId, name) {
        let { data } = await axios.post(`${config.baseUrl}event/group`, { eventId, name }, { headers: getApiKeyHeaders() });
        return data;
    }

    async function updateEventGroup(eventId, groupId, group) {
        let { data } = await axios.patch(`${config.baseUrl}event/${eventId}/group/${groupId}`, group, { headers: getApiKeyHeaders() });
        return data;
    }

    async function registerTeamEvent(eventId, teamId) {
        let { data } = await axios.post(`${config.baseUrl}event/team`, { eventId, teamId }, { headers: getUserHeaders() });
        return data;
    }

    async function unregisterTeamEvent(eventId, teamId) {
        let { data } = await axios.delete(`${config.baseUrl}event/team`, { eventId, teamId }, { headers: getUserHeaders() });
        return data;
    }

    async function checkinTeamEvent(eventId, teamId) {
        let { data } = await axios.post(`${config.baseUrl}event/team/checkin`, { eventId, teamId }, { headers: getUserHeaders() });
        return data;
    }

    async function uncheckinTeamEvent(eventId, teamId) {
        let { data } = await axios.delete(`${config.baseUrl}event/team/checkin`, { eventId, teamId }, { headers: getUserHeaders() });
        return data;
    }

    async function getUserEventMatchInfo(eventId) {
        let { data } = await axios.get(`${config.baseUrl}event/user/${eventId}`, { headers: getUserHeaders() });
        return data;
    }

    async function setEventMatchCode(eventId, matchId, groupId, code) {
        let { data } = await axios.patch(`${config.baseUrl}event/match`, { eventId, matchId, groupId, code }, { headers: getApiKeyHeaders() });
        return data;
    }

    async function clearEventCache(eventId) {
        let { data } = await axios.delete(`${config.baseUrl}event/${eventId}/cache`, { headers: getApiKeyHeaders() });
        return data;
    }

    // users

    function getUser() {
        let token = localStorage.getItem("session-token");
        if (token) {
            return jwtDecode(token);
        }
    }

    async function getUserData(redirect = true) {
        try {
            let data = await axios.get(`${config.baseUrl}user/`, { headers: getUserHeaders() });

            return data.data;
        } catch (err) {
            if (redirect && (err.response.status == 400 || err.response.status == 401)) {
                window.location = "/login"
            }
            return false;
        }
    }

    function userLogout() {
        localStorage.removeItem("session-token");
    }

    async function userLogin(username, password) {
        try {
            let { data } = await axios.post(`${config.baseUrl}user/login`, { username, password });
            console.log(data);
            if (data.token) {
                localStorage.setItem("session-token", data.token);
                return true;
            }
            return false;
        } catch (err) {
            return false;
        }
    }

    async function userCreateAccount(username, email, password) {
        try {
            let { data } = await axios.post(`${config.baseUrl}user/create`, { username, email, password });
            if (data.token) {
                localStorage.setItem("session-token", data.token);
                return true;
            }
            return false;
        } catch (err) {
            console.log(err.response);
            return err.response.data;
        }
    }

    async function userVerifyEmail(token) {
        let data = await axios.post(`${config.baseUrl}user/verify-email`, { token }, { headers: getUserHeaders() });

        return data.data;
    }

    async function linkApexAccount(lobbyCode) {
        let data = await axios.post(`${config.baseUrl}user/link/apex`, { lobbyCode }, { headers: getUserHeaders() });

        return data.data;
    }

    async function unlinkApexAccount() {
        let data = await axios.delete(`${config.baseUrl}user/link/apex`, { headers: getUserHeaders() });

        return data.data;
    }

    async function getApexLinkStatus() {
        let data = await axios.get(`${config.baseUrl}user/link/apex/status`, { headers: getUserHeaders() });

        return data.data;
    }

    async function unlinkDiscordAccount() {
        let { data } = await axios.delete(`${config.baseUrl}user/link/discord`, { headers: getUserHeaders() });

        return data;
    }

    // teams
    async function getUserTeams() {
        let data = await axios.get(`${config.baseUrl}teams`, { headers: getUserHeaders() });

        return data.data;
    }

    async function createTeam(name) {
        let data = await axios.post(`${config.baseUrl}team`, { name }, { headers: getUserHeaders() });

        return data.data;
    }

    async function joinTeam(token) {
        let data = await axios.post(`${config.baseUrl}team/player`, { token }, { headers: getUserHeaders() });
        return data.data;
    }

    async function leaveTeam(teamId, userId) {
        let data = await axios.delete(`${config.baseUrl}team/player/${teamId}/${userId}`, { headers: getUserHeaders() });
        return data.data;
    }

    async function updateTeam(team) {
        let data = await axios.patch(`${config.baseUrl}team`, team, { headers: getUserHeaders() });
        return data.data;
    }

    async function deleteTeam(teamId) {
        let data = await axios.delete(`${config.baseUrl}team/${teamId}`, { headers: getUserHeaders() });
        return data.data;
    }

    // conversations

    async function createConversation(name, users) {
        let { data } = await axios.post(`${config.baseUrl}conversation/`, { name, users }, { headers: getUserHeaders() });
        return data;
    }

    async function getConversations() {
        try {
            let { data } = await axios.get(`${config.baseUrl}conversations/`, { headers: getUserHeaders() });
            return data;
        } catch (err) {
            userLogout();
        }
    }

    async function getConversationMessages(conversationId, after) {
        let { data } = await axios.get(`${config.baseUrl}conversation/${conversationId}/messages${after ? '?after=' + after : ''}`, { headers: getUserHeaders() });
        return data;
    }

    async function sendConversationMessage(conversationId, message) {
        let { data } = await axios.post(`${config.baseUrl}conversation/${conversationId}/message`, { conversationId, message }, { headers: getUserHeaders() });
        return data;
    }

    //search

    async function searchUsersByName(name) {
        let { data } = await axios.get(`${config.baseUrl}user/search?username=${name}`, { headers: getUserHeaders() });
        return data;
    }

    async function searchMatches(start, count, name, org) {
        let { data } = await axios.get(`${config.baseUrl}match/search?start=${start}&count=${count}&name=${name}${org ? '&organizer=' + org : ''}`);
        return data;
    }

    async function getOrgsWithMatches() {
        let { data } = await axios.get(`${config.baseUrl}organizer_match_list`);
        return data;
    }

    async function getTopPlayers() {
        let { data } = await axios.get(`${config.baseUrl}stats/top_players`);
        return data;
    }

    async function getStatsTeamHistoryByName(teamName) {
        let { data } = await axios.get(`${config.baseUrl}stats/team/by_name/${teamName}`);

        return data;
    }

    async function searchStatsTeams(start, count, name) {
        let { data } = await axios.get(`${config.baseUrl}stats/team/search?start=${start}&count=${count}&name=${name}`);
        return data;
    }

    // profiles

    async function getProfileByApexId(userId) {
        let { data } = await axios.get(`${config.baseUrl}user/profile/by_apex/${userId}`);

        return data;
    }

    async function getProfile() {
        let { data } = await axios.get(`${config.baseUrl}user/profile`, { headers: getUserHeaders() });

        return data;
    }

    async function getAvatar(userId) {
        let { data } = await axios.get(`${config.baseUrl}user/avatar/${userId}`);

        return data;
    }

    function getAvatarUrl(userId) {
        return `${config.baseUrl}user/avatar/${userId}`;
    }

    async function setProfile(profile) {
        let { data } = await axios.post(`${config.baseUrl}user/profile`, profile, { headers: getUserHeaders() });

        return data;
    }

    async function setAvatar(image) {
        let form = new FormData();
        form.append("image", image);

        let { data } = await axios.post(`${config.baseUrl}user/avatar`, form, { headers: getUserHeaders() });

        return data;
    }

    async function setAccountLinks(accounts) {
        let { data } = await axios.post(`${config.baseUrl}user/link`, accounts, { headers: getUserHeaders() });
        return data;
    }

    async function getAnalyticsCharacters(map, interval, start, end) {
        let { data } = await axios.get(`${config.baseUrl}analytics/characters?map=${map}&interval=${interval}&start=${start}&end=${end}`, { headers: getUserHeaders() });

        return data;
    }

    async function getAnalyticsDrops(map, interval, start, end) {
        let { data } = await axios.get(`${config.baseUrl}analytics/drops?map=${map}&interval=${interval}&start=${start}&end=${end}`, { headers: getUserHeaders() });

        return data;
    }
    async function getAnalyticsPlayer(playerId, type, map) {
        let { data } = await axios.get(`${config.baseUrl}analytics/player/${playerId}/${type}?map=${map}`, { headers: getUserHeaders() });

        return data;
    }

    async function getLeaderboard(sort, order, mode, start, count) {
        let { data } = await axios.get(`${config.baseUrl}analytics/leaderboard?sort=${sort}&order=${order}&mode=${mode}&start=${start}&count=${count}`, { headers: getUserHeaders() });
        return data;
    }

    async function createTeamFinderPost(post) {
        let { data } = await axios.post(`${config.baseUrl}user/team-finder`, post, { headers: getUserHeaders() });
        return data;
    }

    async function deleteTeamFinderPost() {
        let { data } = await axios.delete(`${config.baseUrl}user/team-finder`, { headers: getUserHeaders() });
        return data;
    }

    async function searchTeamFinderPost(start, count, roles, characters, region) {
        let { data } = await axios.get(`${config.baseUrl}team-finder/search?start=${start}&count=${count}${roles ? '&roles=' + roles.join(",") : ''}${characters ? '&characters=' + characters.join(",") : ''}${region ? '&region=' + region : ''}`);
        return data;
    }

    // LFG

    async function createLft(userId, region, ranks) {
        let { data } = await axios.post(`${config.baseUrl}lfg/user`, { userId, region, ranks });
        return data;
    }

    async function getLfgStatus(userId) {
        let { data } = await axios.get(`${config.baseUrl}lfg/user/${userId}`);
        return data;
    }

    async function deleteLfg(userId) {
        let { data } = await axios.delete(`${config.baseUrl}lfg/user/${userId}`);
        return data;
    }

    function getSubscriptionLink() {
        return `${config.baseUrl}subscribe?userId=${getUser().id}`;
    }


    //

    async function getOrgStats(orgId) {
        let { data } = await axios.get(`${config.baseUrl}organizer/stats/${orgId}`);
        return data;
    }



    return {
        config,

        getApiKey,
        getMatchPageUrl,
        getStats,
        generateStats,
        getBroadcastSettings,
        setBroadcastSettings,
        getPublicSettings,
        setPublicSettings,
        getMatchList,
        getSelectedMatch,
        setSelectedMatch,
        setMatchPolling,
        getMatchPolling,
        getMatchTeams,
        setMatchTeam,
        clearAllMatchTeams,
        getStatsFromCode,
        login,
        getGameList,
        deleteStats,
        getLatest,
        getShortLink,
        getLiveDataList,
        getPlayers,
        getPlayer,
        getPlayerMatches,
        getLiveDataWs,
        getLiveData,
        getLiveDataById,
        getClients,
        setOrganizerDefaultApexClient,
        getOrganizerDefaultApexClient,
        addClient,
        createMatch,
        getMatch,
        editScore,
        exportCsv,
        setDrop,
        getDrops,
        deleteDrop,
        deleteDropAdmin,
        getDropsHistory,
        getMatchById,
        archiveMatch,
        unArchiveMatch,
        cloneMatch,
        cloneDataAndReset,
        updateEventId,
        pageView,
        userLogin,
        userCreateAccount,
        userVerifyEmail,
        getUser,
        getUserData,
        userLogout,
        getApexLinkStatus,
        linkApexAccount,
        unlinkApexAccount,
        getEventList,
        getEvent,
        createEvent,
        addMatchToEvent,
        removeMatchFromEvent,
        createEventGroup,
        updateEvent,
        getEventPageUrl,
        getUserTeams,
        createTeam,
        updateTeam,
        joinTeam,
        leaveTeam,
        deleteTeam,
        registerTeamEvent,
        unregisterTeamEvent,
        checkinTeamEvent,
        uncheckinTeamEvent,
        updateEventGroup,
        clearMatchTeam,
        getUserEventMatchInfo,
        setEventMatchCode,
        getEventAdmin,
        getEventListAdmin,
        clearEventCache,
        createConversation,
        getConversations,
        getConversationMessages,
        sendConversationMessage,
        searchUsersByName,
        searchMatches,
        getOrgsWithMatches,
        getTopPlayers,
        getPlayerPageUrl,
        getStatsTeamHistoryByName,
        searchStatsTeams,
        unlinkDiscordAccount,
        getMapDrawUrl,
        getProfileByApexId,
        getProfile,
        setProfile,
        setAvatar,
        getAvatar,
        getAvatarUrl,
        setAccountLinks,
        sendClientCommand,
        getAnalyticsCharacters,
        getAnalyticsPlayer,
        getAnalyticsDrops,
        createTeamFinderPost,
        searchTeamFinderPost,
        deleteTeamFinderPost,
        createLft,
        getLfgStatus,
        deleteLfg,
        getSubscriptionLink,
        readLiveData,
        getLeaderboard,
        getDraftStatus,
        makeDraftClaim,
        resetDraft,
        getOrgStats
    }
}
