import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: "/",
    name: "index",
    component: () => import("../pages/Index"),
    props: true,
  },
  {
    path: "/login",
    name: "login",
    component: () => import("../pages/LoginPage"),
  },
  {
    path: "/create-account",
    name: "create-account",
    component: () => import("../pages/LoginPage"),
    props: {
      create: true,
    }
  },
  {
    path: "/matches",
    name: "matches",
    component: () => import("../pages/Matches"),
    props: true,
  },
  {
    path: "/stats-team/:teamName?",
    name: "stats-teams",
    component: () => import("../pages/StatsTeam"),
    props: true,
  },
  {
    path: "/liveboard/:board/:code",
    name: "liveboard",
    component: () => import("../pages/LiveBoard.vue"),
    props: true,
  },
  {
    path: "/analytics",
    name: "analytics",
    component: () => import("../pages/Analytics"),
    props: true,
    redirect: { name: "analytics.legends" },
    children: [
      {
        path: "legends",
        name: "analytics.legends",
        props: true,
        component: () => import("../views/analytics/Legends"),
        redirect: { name: "analytics.legends.overview" },
        children: [
          {
            path: "overview",
            name: "analytics.legends.overview",
            component: () => import("../views/analytics/legends/Overview"),
          },
          {
            path: "team-comp",
            name: "analytics.legends.team-comp",
            component: () => import("../views/analytics/legends/TeamComps"),
          }
        ]
      },
      {
        path: "drops",
        name: "analytics.drops",
        props: true,
        component: () => import("../views/analytics/Drops"),
      },
      {
        path: "players",
        name: "analytics.players",
        props: true,
        component: () => import("../views/analytics/Players"),
      },
      {
        path: "teams",
        name: "analytics.teams",
        props: true,
        component: () => import("../views/analytics/Teams"),
      },
    ]
  },
  {
    path: "/map/:remoteId?",
    name: "map",
    component: () => import("../pages/MapDraw"),
    props: true,
  },
  {  
    path: "/team-finder",
    name: "team-finder",
    component: () => import("../pages/TeamFinderPage"),
    props: true,
  },
  {
    path: "/lfg",
    name: "lfg",
    component: () => import("../pages/Lfg"),
    props: true,
  },
  {
    path: "/account",
    name: "account",
    component: () => import("../pages/AccountPage"),
    props: true,
    redirect: { name: "account.overview" },
    children: [
      {
        path: "overview",
        name: "account.overview",
        props: true,
        component: () => import("../views/account/Overview"),
      },
      {
        path: "teams",
        name: "account.teams",
        props: true,
        component: () => import("../views/account/Teams"),
      },
      {
        path: "messages",
        name: "account.messages",
        props: true,
        component: () => import("../views/account/Messages"),
      },
      {
        path: "profile",
        name: "account.profile",
        props: true,
        component: () => import("../views/account/Profile"),
      },
      {
        path: "team-finder",
        name: "account.team-finder",
        props: true,
        component: () => import("../views/account/TeamFinder"),
      }
    ]
  },
  {
    path: "/event/:organizer/:eventSlug",
    name: "event",
    component: () => import("../pages/EventPage"),
    props: true,
    redirect: { name: "event.overview" },
    children: [
      {
        path: "overview",
        name: "event.overview",
        props: true,
        component: () => import("../views/event/Overview"),
      },
      {
        path: "teams",
        name: "event.teams",
        props: true,
        component: () => import("../views/event/Teams"),
      },
      {
        path: "matches/:groupId?/:matchId?",
        name: "event.matches",
        props: true,
        component: () => import("../views/event/Matches"),
      },
      {
        path: "rules",
        name: "event.rules",
        props: true,
        component: () => import("../views/event/Rules"),
      }
    ]
  },
  {
    path: "/broadcast/:organizer/:display",
    name: "broadcast",
    component: () => import("../pages/BroadcastPage"),
    props: true,
  },
  {
    path: '/admin',
    name: 'admin',
    component: () => import("../pages/AdminPage"),
    props: true,
    redirect: { name: "admin.overview" },
    children: [
      {
        path: "overview",
        name: "admin.overview",
        component: () => import("../views/admin/OverviewTab.vue"),
        props: true,
      },
      {
        path: "matches/:matchId?",
        name: "admin.match-manager",
        component: () => import("../views/admin/MatchManagerTab"),
        props: true,
      },
      {
        path: "events",
        name: "admin.event-manager",
        component: () => import("../views/admin/EventTab"),
        props: true,
      },
      {
        path: "broadcast",
        name: "admin.broadcast-manager",
        component: () => import("../views/admin/BroadcastTab"),
        props: true,
      },
      {
        path: "clients",
        name: "admin.clients-manager",
        component: () => import("../views/admin/ObserverClientTab"),
        props: true,
      },
    ]
  },
  {
    path: "/players",
    name: "players",
    component: () => import("../pages/PlayersPage"),
    props: true,
  },
  {
    path: "/player/:id",
    name: "player",
    component: () => import("../pages/PlayerPage"),
    props: true,
    redirect: { name: "player.overview" },
    children: [
      {
        path: "overview",
        name: "player.overview",
        component: () => import("../views/player/Overview"),
        props: true,
      },
      {
        path: "matches",
        name: "player.matches",
        component: () => import("../views/player/Matches"),
        props: true,
      },
      {
        path: "teams",
        name: "player.teams",
        component: () => import("../views/player/Teams"),
        props: true,
      },
      {
        path: "analytics",
        name: "player.analytics",
        component: () => import("../views/player/Analytics"),
        props: true,
        children: [
          {
            path: "overview",
            name: "player.analytics.overview",
            component: () => import("../views/player/analytics/Overview"),
            props: true,
          },
          {
            path: "legends",
            name: "player.analytics.characters",
            component: () => import("../views/player/analytics/Characters"),
            props: true,
          },
          {
            path: "drops",
            name: "player.analytics.drops",
            component: () => import("../views/player/analytics/Drops"),
            props: true
          }
        ]
      }
    ]
  },
  {
    path: "/tournament/:organizer/:matchSlug/",
    name: "tournament",
    component: () => import("../pages/TournamentPage"),
    props: true,
    redirect: { name: "tournament.standings", params: { game: "overall" } },
    children: [
      {
        path: "standings/:game?",
        name: "tournament.standings",
        component: () => import("../views/tournament/Standings"),
        props: true,
        redirect: { name: "tournament.standings.scoreboard" },
        children: [
          {
            path: "scoreboard",
            name: "tournament.standings.scoreboard",
            component: () => import("../views/tournament/standings/Scoreboard"),
            props: true,
          },
          {
            path: "team-standings",
            name: "tournament.standings.team",
            component: () => import("../views/tournament/standings/TeamStandings"),
            props: true,
          },
          {
            path: "player-standings",
            name: "tournament.standings.player",
            component: () => import("../views/tournament/standings/PlayerStandings"),
            props: true,
          },
          {
            path: "feed",
            name: "tournament.standings.feed",
            component: () => import("../views/tournament/standings/Feed"),
            props: true,
          },
          {
            path: "graphic",
            name: "tournament.standings.graphic",
            component: () => import("../views/tournament/standings/Graphic"),
            props: true,
          }
        ]
      },
      {
        path: "stats",
        name: "tournament.stats",
        component: () => import("../views/tournament/Stats"),
        props: true,
        redirect: { name: "tournament.stats.point-ratio" },
        children: [
          {
            path: "charts",
            name: "tournament.stats.charts",
            component: () => import("../views/tournament/stats/CumulativeCharts"),
            props: true,
          },
          {
            path: "game-charts",
            name: "tournament.stats.game-charts",
            component: () => import("../views/tournament/stats/GameCharts"),
            props: true,
          },
          {
            path: "point-ratio",
            name: "tournament.stats.point-ratio",
            component: () => import("../views/tournament/stats/PointRatio"),
            props: true,
          },
          {
            path: "pick-rate",
            name: "tournament.stats.pick-rate",
            component: () => import("../views/tournament/stats/PickRate"),
            props: true,
          }
        ]
      },
      {
        path: "drops/:map?",
        name: "tournament.drops",
        component: () => import("../views/tournament/Drops"),
        props: true,
      },
      {
        path: "replay/:game?",
        name: "tournament.replay",
        component: () => import("../views/tournament/Replay"),
        props: true,
      }
    ]
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
});

router.beforeEach(async (to, from, next) => {
  console.log(to.fullPath, from.fullPath);
  await Vue.prototype.$apex.pageView(to.fullPath, from.fullPath);
  next();
})

export default router;
